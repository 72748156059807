import { ReactNode } from 'react'
import CallbackPage from '../components/pages/Callback'
import DashboardPage from '../components/pages/Dashboard'
import TestsPage from '../components/pages/Tests'
import VerifyPage from '../components/pages/Verify'
import AdminCreateResultsPage from '../components/pages/admin/AdminCreateResultsPage'
import AdminCreateResultsPageNew from '../components/pages/admin/AdminCreateResultsPageNew'
import ResultPage from '../components/pages/Result'
import SettingsPage from '../components/pages/Settings'
import AdminUpdateBiomarkersPage from '../components/pages/admin/AdminUpdateBiomarkers'
import ResultsPage from '../components/pages/Results'
import AdminRawSalesIssuesPage from '../components/pages/admin/AdminRawSaleIssues'
import CollectionCentresPage from '../components/pages/CollectionCentres'
import OrganisationHomePage from '../components/pages/organisation/OrganisationHome'
import OrganisationCreatePage from '../components/pages/organisation/OrganisationCreate'
import { OrgHome } from '../components/pages/organisation/org/Home'
import { OrgSettings } from '../components/pages/organisation/org/Settings'
import { OrgCreateTest } from '../components/pages/organisation/org/CreateTest'
import { StandalonePurchasePage } from '../components/pages/payment/StandalonePurchase'
import { OrgTestBundles } from '../components/pages/organisation/org/TestBundles'
import { OrgTestBundle } from '../components/pages/organisation/org/TestBundle'
import { AdminHome } from '../components/pages/admin/AdminHome'
import { OrgResults } from '../components/pages/organisation/org/Results'
import OrgResult from '../components/pages/organisation/org/Result'
import { OrgReferrals } from '../components/pages/organisation/org/Referrals'
import { OrgStores } from '../components/pages/organisation/org/Stores'

export interface Route {
  handler: () => ReactNode
  name: string
  url: string
}
export type Routes = Record<string, Route>

export enum PageNames {
  'dashboard' = 'dashboard',
  'tests' = 'tests',
  'callback' = 'callback',
  'verify' = 'verify',
  'standalone_buy' = 'standalone_buy',
  'admin' = 'admin',
  'admin_create_results' = 'admin_create_results',
  'admin_create_results_new' = 'admin_create_results_new',
  'admin_update_biomarkers' = 'admin_update_biomarkers',
  'admin_raw_sale_issues' = 'admin_raw_sale_issues',
  'result' = 'result',
  'results' = 'results',
  'settings' = 'settings',
  'collection_centres' = 'collection_centres',
  'organisations_home' = 'organisations_home',
  'organisations_create' = 'organisations_create',
  'organisation_home' = 'organisation_home',
  'organisation_create_test' = 'organisation_create_test',
  'organisation_test_bundles' = 'organisation_test_bundles',
  'organisation_test_bundle' = 'organisation_test_bundle',
  'organisation_settings' = 'organisation_settings',
  'organisation_results' = 'organisation_results',
  'organisation_referrals' = 'organisation_referrals',
  'organisation_result' = 'organisation_result',
  'organisation_stores' = 'organisation_stores',
}

export const orgHomePrefix = '/org'

export const dashboardUrl = '/'
export const testsUrl = '/tests'
export const resultsUrl = '/results'
export const resultUrl = '/results/:uuid/*'
export const callbackUrl = '/callback'
export const verifyUrl = '/verify'
export const standaloneBuyUrl = '/buy/:id'
export const collectionCentreUrl = '/collection-centres'
export const organisationsHomeUrl = '/organisations'
export const organisationCreateUrl = '/organisations/create'

export const organisationHomeUrl = `${orgHomePrefix}/:id`
export const organisationCreateTestUrl = `${orgHomePrefix}/:id/create-test`
export const organisationTestBundlesUrl = `${orgHomePrefix}/:id/bundles`
export const organisationTestBundleUrl = `${orgHomePrefix}/:id/bundles/:bundleId`
export const organisationStoreUrl = `${orgHomePrefix}/:id/settings/integrations/stores/:storeUuid`
export const organisationSettingsUrl = `${orgHomePrefix}/:id/settings/*`
export const organisationResultsUrl = `${orgHomePrefix}/:id/results`
export const organisationResultUrl = `${orgHomePrefix}/:id/results/:uuid/*`
export const organisationReferralsUrl = `${orgHomePrefix}/:id/referrals`

export const adminUrl = '/admin'
export const adminResultsCreateUrl = '/admin/results/create'
export const adminResultsCreateNewUrl = '/admin/results/create/new'
export const adminBiomarkersUpdateUrl = '/admin/biomarkers/update'
export const adminRawSaleIssuesUrl = '/admin/rawsale/issues'

export const settingsUrl = '/settings/*'

export const settingsAccountUrl = '/settings'
export const settingsHealthRecordUrl = `${settingsAccountUrl}/health`

export const orgSettingsRegex = `^${organisationSettingsUrl
  .replace('/*', '(.*)')
  .replace('/:id', '/([A-Za-z0-9-]*)')
  .replace(/\//gi, '\\/')}$`

export const orgResultUrlRegex = `^${organisationResultUrl
  .replace('/*', '(.*)')
  .replace('/:id', '/([A-Za-z0-9-]*)')
  .replace('/:uuid', '/([A-Fa-f0-9-]*)')
  .replace(/\//gi, '\\/')}$`

export const resultUrlRegex = `^${resultUrl
  .replace('/*', '(.*)')
  .replace('/:uuid', '/([A-Fa-f0-9-]*)')
  .replace(/\//gi, '\\/')}$`

export const settingsUrlRegex = `^${settingsUrl
  .replace('/*', '(.*)')
  .replace(/\//gi, '\\/')}$`

export const authExcludedRoutes = [
  collectionCentreUrl,
  standaloneBuyUrl
    .replace(':id', '([a-zA-Z0-9-]+)/?.*$')
    .replace(/\//gi, '\\/'),
  verifyUrl,
]

export const routes: Routes = {
  [verifyUrl]: {
    handler: VerifyPage,
    name: PageNames.verify,
    url: verifyUrl,
  },
  [collectionCentreUrl]: {
    handler: CollectionCentresPage,
    name: PageNames.collection_centres,
    url: collectionCentreUrl,
  },
  [standaloneBuyUrl]: {
    handler: StandalonePurchasePage,
    name: PageNames.standalone_buy,
    url: standaloneBuyUrl,
  },
  [testsUrl]: {
    handler: TestsPage,
    name: PageNames.tests,
    url: testsUrl,
  },
  [resultsUrl]: {
    handler: ResultsPage,
    name: PageNames.results,
    url: resultsUrl,
  },
  [resultUrl]: {
    handler: ResultPage,
    name: PageNames.results,
    url: resultUrl,
  },
  [organisationHomeUrl]: {
    handler: OrgHome,
    name: PageNames.organisation_home,
    url: organisationHomeUrl,
  },
  [organisationCreateTestUrl]: {
    handler: OrgCreateTest,
    name: PageNames.organisation_create_test,
    url: organisationCreateTestUrl,
  },
  [organisationTestBundlesUrl]: {
    handler: OrgTestBundles,
    name: PageNames.organisation_test_bundles,
    url: organisationTestBundlesUrl,
  },
  [organisationTestBundleUrl]: {
    handler: OrgTestBundle,
    name: PageNames.organisation_test_bundle,
    url: organisationTestBundleUrl,
  },
  [organisationResultsUrl]: {
    handler: OrgResults,
    name: PageNames.organisation_results,
    url: organisationResultsUrl,
  },
  [organisationReferralsUrl]: {
    handler: OrgReferrals,
    name: PageNames.organisation_referrals,
    url: organisationReferralsUrl,
  },

  [organisationResultUrl]: {
    handler: OrgResult,
    name: PageNames.organisation_result,
    url: organisationResultUrl,
  },
  [organisationSettingsUrl]: {
    handler: OrgSettings,
    name: PageNames.organisation_settings,
    url: organisationSettingsUrl,
  },
  [organisationStoreUrl]: {
    handler: OrgStores,
    name: PageNames.organisation_stores,
    url: organisationStoreUrl,
  },

  [organisationsHomeUrl]: {
    handler: OrganisationHomePage,
    name: PageNames.organisations_home,
    url: organisationsHomeUrl,
  },
  [organisationCreateUrl]: {
    handler: OrganisationCreatePage,
    name: PageNames.organisations_create,
    url: organisationCreateUrl,
  },
  [settingsUrl]: {
    handler: SettingsPage,
    name: PageNames.settings,
    url: settingsUrl,
  },
  [callbackUrl]: {
    handler: CallbackPage,
    name: PageNames.callback,
    url: callbackUrl,
  },
  [adminUrl]: {
    handler: AdminHome,
    name: PageNames.admin,
    url: adminUrl,
  },
  [adminResultsCreateUrl]: {
    handler: AdminCreateResultsPage,
    name: PageNames.admin_create_results,
    url: adminResultsCreateUrl,
  },
  [adminResultsCreateNewUrl]: {
    handler: AdminCreateResultsPageNew,
    name: PageNames.admin_create_results_new,
    url: adminResultsCreateNewUrl,
  },

  [adminBiomarkersUpdateUrl]: {
    handler: AdminUpdateBiomarkersPage,
    name: PageNames.admin_update_biomarkers,
    url: adminBiomarkersUpdateUrl,
  },
  [adminRawSaleIssuesUrl]: {
    handler: AdminRawSalesIssuesPage,
    name: PageNames.admin_raw_sale_issues,
    url: adminRawSaleIssuesUrl,
  },

  [dashboardUrl]: {
    handler: DashboardPage,
    name: PageNames.dashboard,
    url: dashboardUrl,
  },
}

export function findMatchingRoute(url: string): Route | undefined {
  const routePaths = Object.keys(routes)
  let match: Route | undefined

  for (const r in routePaths) {
    const item = routePaths[r]
    // console.log('LETS FIND THE ROUTE', item)
    const newItem =
      '^' +
      item
        .replace('\\/', '/')
        .replace(/:\w+/gi, '([\\w-]+)')
        .replace('/*', '.*') +
      '\\/?$'
    // console.log('routePath>> ', newItem)
    //\/buy\/([a-zA-Z0-9-]+)\/?.*$
    const re = new RegExp(newItem)
    const result = re.test(url)
    // console.log("result=>", result);
    if (result === true) {
      match = routes[item]
      // console.log("we found it! - exit now!");
      break
    }
  }
  return match
}

export function getBundleUrl(orgId: string, bundleUuid: string) {
  return organisationTestBundleUrl
    .replace(':id', orgId)
    .replace(':bundleId', bundleUuid)
}

export function getBundlesUrl(orgId: string | undefined): string {
  if (!orgId) {
    return ''
  }
  return organisationTestBundlesUrl.replace(':id', orgId)
}
export function getOrgUrl(orgId: string | undefined): string {
  if (!orgId) {
    return ''
  }
  return organisationHomeUrl.replace(':id', orgId)
}
export function getCreateTestBundleUrl(orgId: string | undefined): string {
  if (!orgId) {
    return ''
  }
  return organisationCreateTestUrl.replace(':id', orgId)
}

export function getOrgResultUrl(orgId: string, resultUuid: string) {
  return organisationResultUrl
    .replace(':id', orgId)
    .replace(':uuid', resultUuid)
    .replace('/*', '')
}

export function getStoreUrl(orgId: string, storeUuid: string) {
  return organisationStoreUrl
    .replace(':id', orgId)
    .replace(':storeUuid', storeUuid)
}
