import { authFetch } from '../providers/AuthProvider'
import {
  BiomarkerItem,
  BiomarkerListSchema,
  PathologyProvider,
  PathologyProviderListSchema,
  ReferralStatusSchemaList,
  ReferralStatusType,
} from './validators'

export const getIssuedReferrals = async (): Promise<ReferralStatusType[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          referralsByStatus(status:["ISSUED", "PARTIAL_RESULTS"]) {
            uuid
            status
            reference
            profileUuid
            organisationUuid
            testName
            customerName
            createdAt
            resultUuid
          }
        }`,
    }),
  })
  const data = result.data
  console.log('the data is', data.data)
  const parsedData = ReferralStatusSchemaList.parse(data.data.referralsByStatus)
  console.log(parsedData)
  return parsedData
}

export const getPathologyProviders = async (): Promise<PathologyProvider[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          pathologyProviders {
            id
            name
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = PathologyProviderListSchema.parse(
    data.data.pathologyProviders
  )
  return parsedData
}

export const biomarkerItemFields = `
            id
            name
            commonName
            suffix
            prefix
            unit
            refIntervalHigh
            refIntervalLow
            description
            refIntervalHighNotes
            refIntervalLowNotes
            refIntervalMidNotes`

export const getBiomarkersForReferralUuid = async (
  referralUuid: string
): Promise<BiomarkerItem[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          biomarkersForReferral(referralUuid:"${referralUuid}") {
            ${biomarkerItemFields}
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = BiomarkerListSchema.parse(data.data.biomarkersForReferral)
  return parsedData
}

export enum ResultStatusTypes {
  normal,
  abnormal,
}

export const getResultStatus = (
  value?: string,
  refLow?: string,
  refHigh?: string
): ResultStatusTypes => {
  if (refHigh !== undefined && refLow !== undefined && value !== undefined) {
    if (
      parseFloat(value) > parseFloat(refHigh) ||
      parseFloat(value) < parseFloat(refLow)
    ) {
      return ResultStatusTypes.abnormal
    }
    return ResultStatusTypes.normal
  }

  if (refHigh !== undefined && refLow === undefined && value !== undefined) {
    if (parseFloat(value) > parseFloat(refHigh)) {
      return ResultStatusTypes.abnormal
    }
    return ResultStatusTypes.normal
  }

  if (refHigh === undefined && refLow !== undefined && value !== undefined) {
    if (parseFloat(value) < parseFloat(refLow)) {
      return ResultStatusTypes.abnormal
    }
    return ResultStatusTypes.normal
  }
  return ResultStatusTypes.normal
}
