export enum ReferralStatus {
  PendingVerification = 'PENDING_VERIFICATION',
  Created = 'CREATED',
  Issued = 'ISSUED',
  PartialResults = 'PARTIAL_RESULTS',
  Complete = 'COMPLETE',
  InsufficientData = 'INSUFFICIENT_DATA',
  Refunded = 'REFUNDED',
  TestFailed = 'FAILED_TEST',
}

export enum GenderTypes {
  Male = 'male',
  Female = 'female',
}

export enum ResultStatus {
  Created = 'CREATED',
  InReview = 'IN_REVIEW',
  PartialResults = 'PARTIAL_RESULTS',
  Complete = 'COMPLETE',
}
