import { PageHeading } from '../../../layout/PageHeading'
import PageSection from '../../../layout/PageSection'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import { setDocumentTitle } from '../../../../lib/utils'
import { Fragment, useCallback, useEffect, useState } from 'react'
import OrgPage from '../../../OrgPage'
import {
  addProductsToStore,
  getProductsForStore,
  getStoreForOrganisationUuid,
  removeProductsFromStore,
  updateStore,
} from '../../../../lib/organisation'
import { useParams } from 'react-router-dom'
import PageLoader from '../../../layout/PageLoader'
import { ActiveProducts, Product, Store } from '../../../../lib/validators'
import toast from 'react-hot-toast'
import {
  GroupSection,
  GroupSectionItem,
  GroupSections,
} from '../../../layout/GroupSection'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ClipboardDocumentListIcon, TrashIcon } from '@heroicons/react/24/solid'
import TextInputField from '../../../form/TextInputField'
import { Button } from '../../../form/button/Button'
import { InfoSection } from '../../../ui/InfoSection'
import { diff } from 'deep-object-diff'
import { BasicTableSimpleProps } from '../../../layout/BasicTable'
import { ConfirmDeleteModal } from './modals/ConfirmDeleteModal'
import { PickProductsModal } from './modals/PickProductsModal'

export function OrgStores() {
  const { org, theme } = useOrganisationContext()
  const [loading, setLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [store, setStore] = useState<Store>()
  const [webhookUrl, setWebhookUrl] = useState('')
  const [storeSigningSecret, setStoreSigningSecret] = useState<string>('')
  const [selectedForDelete, setSelectedForDelete] = useState<
    Product | undefined
  >()
  const [originalState, setOriginalState] = useState<Store>()
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [products, setProducts] = useState<Product[]>()
  const [activeProductMap, setActiveProductMap] = useState<ActiveProducts>({})
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [openPickProductsModal, setOpenPickProductsModal] = useState(false)
  const [tableData, setTableData] = useState<
    BasicTableSimpleProps | undefined
  >()
  const params = useParams()

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!org || !store) {
      return
    }

    setIsSaving(true)
    const savedStore = await updateStore({
      storeSigningSecret: storeSigningSecret,
      organisationUuid: org.uuid,
      uuid: store?.uuid,
    })
    setIsSaving(false)
    setStore(savedStore)
    setOriginalState(savedStore)
    toast.success('Store updated', {
      duration: 3000,
    })
  }

  useEffect(() => {
    if (store) {
      setStoreSigningSecret(store.storeSigningSecret || '')
      setWebhookUrl(
        `${import.meta.env.VITE_API_URL}/webhooks/${store.id}/${store?.storeProvider}/ordercreate`
      )
      setDocumentTitle(`${store.name} Store`, 'BRANDED')
    }
  }, [store])

  useEffect(() => {
    if (originalState && store) {
      const theDiff = diff(originalState, store)
      if (Object.keys(theDiff).length > 0) {
        // we have to at least have a valid string for the signature
        if (store.storeSigningSecret?.trim() === '') {
          setSaveEnabled(false)
          return
        }
        setSaveEnabled(true)
        return
      }
      setSaveEnabled(false)
    }
  }, [store, originalState])

  useEffect(() => {
    if (!products) {
      return
    }
    const tmpActiveProducts: ActiveProducts = {}
    products.map((p) =>
      p.externalIdentifier
        ? (tmpActiveProducts[p.id] = {
            externalId: p.externalIdentifier,
            id: p.id,
            name: p.name,
          })
        : false
    )
    setActiveProductMap(tmpActiveProducts)
  }, [products])

  const getLatestStoreState = useCallback(() => {
    if (params.storeUuid && org) {
      Promise.all([
        getStoreForOrganisationUuid(org.uuid, params.storeUuid),
        getProductsForStore(org.uuid, params.storeUuid),
      ])
        .then(([theStore, theProducts]) => {
          setStore(theStore)
          setOriginalState(theStore)
          setProducts(theProducts)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [org, params.storeUuid])

  useEffect(() => {
    // getStoreForOrganisationUuid
    if (org && params.storeUuid) {
      // these should be combined into a single graphql request
      getLatestStoreState()
    }
  }, [params.storeUuid, org, getLatestStoreState])

  const removeItem = useCallback(
    (productId: string) => {
      console.log('remove the item', productId)
      if (!products) {
        return
      }
      const theProduct = products.filter((p) =>
        p.id === productId ? true : false
      )
      if (theProduct.length === 1) {
        setSelectedForDelete(theProduct[0])
        setOpenConfirmDeleteModal(true)
      }
    },
    [products]
  )

  const deleteProductFromList = async (identifier?: string) => {
    console.log('we need to delete this one', identifier)
    if (!identifier) {
      return
    }
    if (!org || !store) {
      return
    }
    await removeProductsFromStore(org?.uuid, store?.uuid, [identifier])

    // remove it from the database
    const newProducts = products?.filter((p) =>
      p.id === identifier ? false : true
    )
    setProducts(newProducts)
    toast.success(`${selectedForDelete?.name} deleted from store`, {
      duration: 3000,
    })
    setSelectedForDelete(undefined)
  }

  const massageTestDataIntoTable = useCallback(
    (products: Product[]) => {
      const result = []

      // console.log("referrals", referrals);
      for (const i in products) {
        const product = products[i]
        // console.log("files", referrals[i].fileUuids);
        result.push([
          product.name,
          product.description || '',
          product.externalIdentifier,
          <TrashIcon
            width={20}
            className="cursor-pointer text-[--active-color] hover:text-[--active-hover-color]"
            style={
              theme
                ? ({
                    '--active-color': theme.linkColor,
                    '--active-hover-color': theme.linkHoverColor,
                  } as React.CSSProperties)
                : {}
            }
            onClick={() => removeItem(product.id)}
          ></TrashIcon>,
        ])
      }
      setTableData({
        columns: [
          'Name',
          'Description',
          <div>{store?.storeProvider} product id</div>,
          'Delete?',
        ],
        data: result,
      })
    },
    [store, theme, removeItem]
  )

  useEffect(() => {
    if (products !== undefined) {
      massageTestDataIntoTable(products)
    }
  }, [products, massageTestDataIntoTable])

  const colClasses = [
    'text-left col-span-3 sm:col-span-4 border-b-1 border-gray-dark content-center pt-4 rounded-tl-lg rounded-bl-lg', //name
    'text-left col-span-3 sm:col-span-3  text-gray-semi-dark ', //description
    'text-left col-span-2 sm:col-span-3 sm:text-center text-gray-semi-dark content-center', //reference
    'text-left col-span-1 sm:col-span-2 sm:text-right text-gray-semi-dark content-center pb-4 rounded-tr-lg rounded-br-lg', // status
    // "sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3", // status
  ]

  const addNewProductsToStore = async (items: ActiveProducts) => {
    if (!org || !store) {
      return
    }
    setLoading(true)
    await addProductsToStore(org.uuid, store.uuid, Object.values(items))
    getLatestStoreState()
    // setLoading(false)
  }

  return (
    <Fragment>
      {org && (
        <OrgPage
          org={org}
          theme={theme || undefined}
          tabs={[
            { name: 'Settings', href: `/org/${org.id}/settings` },
            {
              name: 'Integrations',
              href: `/org/${org.id}/settings/integrations`,
            },
            {
              name: 'Store',
            },
            { name: store ? store.name : 'Loading' },
          ]}
        >
          {store && (
            <PageSection>
              <PageHeading title={store.name}></PageHeading>
              <form onSubmit={handleSubmit}>
                <GroupSections>
                  <GroupSection
                    title="Store Products"
                    description={
                      <div>
                        Listed below is all your bundles. Please check the box
                        in the last column to
                        <div>
                          <Button
                            label="Add saved bundle to store"
                            onClick={() => setOpenPickProductsModal(true)}
                          ></Button>
                          <PickProductsModal
                            org={org}
                            theme={theme || undefined}
                            show={openPickProductsModal}
                            setShow={setOpenPickProductsModal}
                            dismissOnSelect={true}
                            btnLabel="Add products to store"
                            selected={activeProductMap}
                            provider={store.storeProvider}
                            onSelect={addNewProductsToStore}
                            message={`Are you sure you want to delete ${selectedForDelete?.name} from your ${store.storeProvider} store?`}
                          ></PickProductsModal>
                        </div>
                      </div>
                    }
                    underneath
                  >
                    {tableData && tableData.data.length > 0 && (
                      <div
                        key={`test-table`}
                        className="grid grid-cols-3 sm:grid-cols-12 mt-10"
                      >
                        {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
                        <div
                          className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm px-2 py-1 sm:p-3 sm:px-4 hidden sm:block ${colClasses[0]}`}
                        >
                          {tableData.columns[0]}
                        </div>
                        <div
                          className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[1]}`}
                        >
                          {tableData.columns[1]}
                        </div>
                        <div
                          className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[2]}`}
                        >
                          {tableData.columns[2]}
                        </div>
                        <div
                          className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm px-2 py-1 sm:p-3 hidden sm:block ${colClasses[3]}`}
                        >
                          {tableData.columns[3]}
                        </div>
                        {tableData.data.map((row, k) =>
                          row.map((item, i) => (
                            <div
                              key={`item-row-${k}-${i}`}
                              className={`py-1 sm:p-3 sm:px-4 col-span-1 sm:col-span-2 text-md text-black dark:text-white  ${
                                k % 2 === 1
                                  ? 'bg-gray-light dark:bg-dark-gray-light'
                                  : ''
                              } ${colClasses[i]} ${
                                i !== row.length - 1
                                  ? ''
                                  : 'last-row sm:border-none border-b border-gray '
                              }`}
                            >
                              <div className="inline-block align-middle">
                                {item}
                              </div>
                            </div>
                          ))
                        )}
                        <ConfirmDeleteModal
                          org={org}
                          theme={theme || undefined}
                          show={openConfirmDeleteModal}
                          setShow={setOpenConfirmDeleteModal}
                          dismissOnDelete={true}
                          identifier={selectedForDelete?.id}
                          onDelete={deleteProductFromList}
                          onDismiss={() =>
                            setTimeout(
                              () => setSelectedForDelete(undefined),
                              500
                            )
                          }
                          message={`Are you sure you want to delete ${selectedForDelete?.name} from your ${store.storeProvider} store?`}
                        ></ConfirmDeleteModal>
                      </div>
                    )}
                    {(!tableData ||
                      (tableData && tableData.data.length === 0)) && (
                      <div className="mt-2 text-md">
                        No items found in store.
                      </div>
                    )}
                  </GroupSection>
                  <GroupSection
                    title="Webhook Configuration"
                    description={
                      <div>
                        {store.storeProvider === 'shopify' && (
                          <p>
                            See{' '}
                            <a
                              className={`text-[--active-color] hover:text-[--active-hover-color]`}
                              href=""
                              style={
                                theme
                                  ? ({
                                      '--active-color': theme.linkColor,
                                      '--active-hover-color':
                                        theme.linkHoverColor,
                                    } as React.CSSProperties)
                                  : {}
                              }
                            >
                              Setting up my Shopify Webhook.
                            </a>
                          </p>
                        )}
                        {store.storeProvider === 'webflow' && (
                          <p>
                            See <a href="">Setting up my Webflow Webhook.</a>
                          </p>
                        )}
                      </div>
                    }
                  >
                    <GroupSectionItem title="Webhook URL">
                      <div className="mt-2 mb-4">
                        <InfoSection>
                          This is the URL you need to use in your{' '}
                          {store.storeProvider} store to send events to Bloody
                          Good Tests.
                        </InfoSection>
                      </div>
                      <span className="p-4 bg-gray-100 rounded-lg inline-block">
                        <span
                          className="inline-block truncate max-w-96"
                          title={webhookUrl}
                        >
                          {webhookUrl}
                        </span>

                        <CopyToClipboard
                          text={webhookUrl}
                          onCopy={() =>
                            toast.success('Webhook URL copied to clipboard', {
                              duration: 3000,
                            })
                          }
                        >
                          <ClipboardDocumentListIcon
                            width={20}
                            title="Copy to clipboard"
                            className="inline cursor-pointer ml-2 text-[--active-color] hover:text-[--active-hover-color]"
                            style={
                              theme
                                ? ({
                                    '--active-color': theme.linkColor,
                                    '--active-hover-color':
                                      theme.linkHoverColor,
                                  } as React.CSSProperties)
                                : {}
                            }
                          ></ClipboardDocumentListIcon>
                        </CopyToClipboard>
                      </span>
                    </GroupSectionItem>
                    {store.storeProvider === 'shopify' && (
                      <Fragment>
                        <GroupSectionItem title="Webhook signature">
                          <div className="mt-2 mb-4">
                            <InfoSection>
                              When webhook events are sent to us by Shopify, we
                              need to ensure they are coming from your shop
                              specifically. In order to ensure this is correct,
                              we need your webhook signature to ascertain that
                              the payload being sent is valid. You can find this
                              value in your Shopify Shop Settings -&gt;
                              Notifications -&gt; Webhooks section.
                            </InfoSection>
                          </div>
                          <TextInputField
                            id="storeSigningSecret"
                            name="storeSigningSecret"
                            type="text"
                            required={true}
                            label={
                              <div>
                                Store Signing Secret{' '}
                                <span className="text-blood font-bold">*</span>
                                <a
                                  className="text-blood text-sm pl-4 float-right"
                                  href="/help/"
                                >
                                  What does this mean?
                                </a>
                              </div>
                            }
                            value={storeSigningSecret}
                            handleChange={(e) => {
                              setStore({
                                ...store,
                                storeSigningSecret: e.strValue,
                              })
                            }}
                          ></TextInputField>
                        </GroupSectionItem>
                        <GroupSectionItem>
                          <Button
                            type="submit"
                            loading={isSaving}
                            disabled={isSaving || !saveEnabled}
                            label="Save"
                            theme={theme || undefined}
                          ></Button>
                        </GroupSectionItem>
                      </Fragment>
                    )}
                  </GroupSection>
                </GroupSections>
              </form>
            </PageSection>
          )}
          {loading && <PageLoader></PageLoader>}
        </OrgPage>
      )}
    </Fragment>
  )
}
